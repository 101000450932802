import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const ONama = () => {

    return (
        <Layout>
            <SEO title='O Nama' />
            <h3>Osnivač portala:</h3>
            <p>UG „RAZVOJ UE“, Stevana Mokranjca 10, Užice</p>
            <p>matični broj: 28135831</p>
            <p>PIB 108407962</p>
            <p>www.vodiczastarije.rs</p>
            <p>Glavni i odgovorni urednik:</p>
            <p>Slavica Panić</p>
            <p>slavicapanicue@gmail.com</p>
            <p>e-mail redakcije: vodicstariji031@gmail.com</p>
            <p>Vodiczastarije.rs je upisan u Registar medija kod ARP </p>
        </Layout>
    );
};

export default ONama;